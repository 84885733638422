import React, { useState, useEffect } from 'react';
import { Endpoint } from '../util/endpoint.util';
import useEndpoint from '../hooks/endpoint.hook';

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  Box,
  Divider,
  Button,
} from '@mui/material';

import AlertMessageComponent from '../components/AlertMessage';
import { useGarageLocation } from '../components/Location';

async function CardNumfromStallNum(location: string, stall_num: number, endpoint: Endpoint): Promise<number> {
  const res = await endpoint.get('sotefin/api/stall_num_to_card_num', {
    params: {
      location: location,
      stall: stall_num
    }
  })
  const card_num = res.data.card_num
  return card_num
}

const Sotefin = () => {
  const endpoint = useEndpoint();
  const { garageLocation } = useGarageLocation()!;

  const [message, setMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const [swapQueue, setSwapQueue] = useState([]);
  const [exitQueue, setExitQueue] = useState([]);

  const [evStalls, setEvStalls] = useState<any[]>([]);
  const [smartcarsInStdStalls, setSmartcarsInStdStalls] = useState<any[]>([]);
  const [dumbcarsInStdStalls, setDumbcarsInStdStalls] = useState<any[]>([]);

  // should make new updated BE endpoint to cleaned up data using standardized card_num, stall_num etc
  // set swap queue
  useEffect(() => {
    endpoint?.get('sotefin/api/get_swap_queue', {
      params: {
        location: garageLocation,
      },
      withCredentials: true
    }).then(response => {
        setSwapQueue(response.data);
    }).catch(error => {
      console.error('There was an error getting the swap queue!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);
  
  // should make new updated BE endpoint to cleaned up data using standardized card_num, stall_num etc
  // set exit queue
  useEffect(() => {
    endpoint?.get('sotefin/api/get_exit_queue', {
      params: {
        location: garageLocation
      },
      withCredentials: true
    }).then(response => {
        setExitQueue(response.data);
    }).catch(error => {
      console.error('There was an error getting the exit queue!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);

  // set ev stalls
  useEffect(() => {
    endpoint?.get('sotefin/api/get_ev_stalls', {
      params: {
        location: garageLocation
      },
      withCredentials: true
    }).then(async response => {
        const data = response.data
        let queue: any[] = [];
        
        for (let i = 0; i < data.length; i++) {
          let stalls = []
          for (let j = 0; j < data[i]['stall_nums'].length; j++) {
            const card_num = await CardNumfromStallNum(garageLocation, data[i]['stall_nums'][j], endpoint);
            stalls.push({
              stall_num : data[i]['stall_nums'][j],
              card_num : card_num
            })
          }
          queue.push({
            evse_location: data[i]['evse_location'],
            stalls: stalls
          })
        }
        setEvStalls(queue);
        console.log(`queue: ${JSON.stringify(queue)}`)
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);

  // set smartcars in non ev stalls
  useEffect(() => {
    endpoint?.get('sotefin/api/get_smartcars_in_garage', {
      params: {
        location: garageLocation
      },
      withCredentials: true
    }).then(async response => {
      let queue = response.data;
        
      const parsedQueue = queue.map((item: { nr: any; status: any }) => ({
        stall_num: item.nr,
        card_num: item.status,
      }));
      
      setSmartcarsInStdStalls(parsedQueue);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);

  // set dumbcars in non ev stalls
  useEffect(() => {
    endpoint?.get('sotefin/api/get_dumbcars_in_garage', {
      params: {
        location: garageLocation
      },
      withCredentials: true
    }).then(async response => {
        let queue = response.data;
        
        const parsedQueue = queue.map((item: { nr: any; status: any }) => ({
          stall_num: item.nr,
          card_num: item.status,
        }));
        
        setDumbcarsInStdStalls(parsedQueue);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);

  const handleSwapCar = (card_num: number) => {
    endpoint?.post('sotefin/api/swap_car', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleExitCar = (card_num: number) => {
    endpoint?.post('sotefin/api/exit_car', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const newHandleSwapCar = (card_num: number) => {
    endpoint?.post('sotefin/api/add_card_to_swap_queue', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const newHandleSwapEVCar = (card_num: number) => {
    endpoint?.post('sotefin/api/add_ev_card_to_swap_queue', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const newHandleExitCar = (card_num: number) => {
    endpoint?.post('sotefin/api/add_card_to_exit_queue', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleDeleteSwapEntry = (card_num: number) => {
    endpoint?.post('sotefin/api/delete_swap_entry', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const newHandleDeleteSwapEVEntry = (card_num: number) => {
    endpoint?.post('sotefin/api/remove_ev_card_from_swap_queue', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const newHandleDeleteSwapEntry = (card_num: number) => {
    endpoint?.post('sotefin/api/remove_ev_card_from_swap_queue', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const newHandleDeleteExitEntry = (card_num: number) => {
    endpoint?.post('sotefin/api/remove_card_from_exit_queue', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  return(
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Sotefin Control
      </Typography>

      <QueueCards
        title="Swap Queue"
        queue={swapQueue}
        handleDeleteSwapEntry={handleDeleteSwapEntry}
        newHandleDeleteSwapEntry={newHandleDeleteSwapEntry}
        newHandleDeleteSwapEVEntry={newHandleDeleteSwapEVEntry}
      />

      <QueueCards
        title="Exit Queue"
        queue={exitQueue}
        newHandleDeleteExitEntry={newHandleDeleteExitEntry}
      />

      <Paper elevation={2} sx={{ marginY: 2 }}>
        <EVStallCards
          evStalls={evStalls}
          swapCar={handleSwapCar}
          exitCar={handleExitCar}
          newSwapEVCar={newHandleSwapEVCar}
          newExitCar={newHandleExitCar}
        />
      </Paper>

      <Paper elevation={2} sx={{ marginY: 2 }}>
        <StandardStallCards
          smartcarStalls={smartcarsInStdStalls}
          dumbcarStalls={dumbcarsInStdStalls}
          newSwapCar={newHandleSwapCar}
          newExitCar={newHandleExitCar}
        />
      </Paper>

      <AlertMessageComponent message={message} isErrorMessage={isErrorMessage} />
    </Box>
  )
};

interface Car {
  id: string;
  card: string; // AKA card_num
  slot: string; // AKA stall_num
}

interface EVStalls {
  evse_location: string;
  stall_nums: number[];
  stalls: {stall_num: number, card_num: number}[]
}

interface EVStallsProps {
  evStalls: EVStalls[];
  swapCar: (stall_num: number) => void;
  exitCar: (stall_num: number) => void;
  newExitCar?: (stall_num: number) => void;
  newSwapEVCar?: (stall_num: number) => void;
}

const EVStallCards: React.FC<EVStallsProps> = ({ evStalls, swapCar, exitCar, newExitCar, newSwapEVCar }) => {

  const [stallNumber, setStallNumber] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<number>(0);

  return(
    <>
      <Typography variant="h4" align="center" gutterBottom>
        EV Stalls
      </Typography>
      {evStalls.map((swapProps) => (
        <Paper elevation={2} sx={{ marginY: 2, marginX: 2 }} key={swapProps['evse_location']}>
          <Typography variant="h6" align="center">
            {swapProps['evse_location']}
          </Typography>
          <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
            {swapProps['stalls'].map((stall: any) => (
              <EVCard key={stall['stall_num']} stall_num={stall['stall_num']} card_num={stall['card_num']} setCardNumber={setCardNumber} stallNumber={stallNumber} setStallNumber={setStallNumber} />
            ))}
            <Grid item>
              
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button 
            variant="contained" 
            onClick={() => swapCar(cardNumber)}
            sx={{ margin: 2 }}
          >
            Swap Car
          </Button>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            onClick={() => exitCar(cardNumber)}
            sx={{ margin: 2 }}
          >
            Exit Car
          </Button>
        </Grid>
      </Grid>

      {newSwapEVCar && newExitCar && (
        <Grid container justifyContent="flex-end">
          <Typography variant="h6" align="center">
            Beta Feature
          </Typography>
          <Grid item>
            <Button 
              variant="contained" 
              onClick={() => newSwapEVCar(cardNumber)}
              sx={{ margin: 2 }}
            >
              Swap Car
            </Button>
          </Grid>
          <Grid item>
            <Button 
              variant="contained" 
              onClick={() => newExitCar(cardNumber)}
              sx={{ margin: 2 }}
            >
              Exit Car
            </Button>
          </Grid>
        </Grid>
      )}

    </>
  )
}



interface StandardStallProps {
  stall_num: number;
  card_num: number;
}

interface StandardStallsProps {
  smartcarStalls: StandardStallProps[];
  dumbcarStalls: StandardStallProps[];
  newSwapCar?: (stall_num: number) => void;
  newExitCar?: (stall_num: number) => void;
}

const StandardStallCards: React.FC<StandardStallsProps> = ({ smartcarStalls, dumbcarStalls, newSwapCar, newExitCar, }) => {

  const [cardNumber, setCardNumber] = useState<number>(0);

  console.log(`smartcarStalls: ${JSON.stringify(smartcarStalls)}, dumbcarStalls: ${JSON.stringify(dumbcarStalls)}`)
  return(
    <>
      <Typography variant="h4" align="center" gutterBottom>
        Standard Stalls
      </Typography>

      <Paper elevation={2} sx={{ marginY: 2, marginX: 2 }}>
        <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>

        {smartcarStalls.map((car) =>
          <StdCard
            card_num={car.card_num}
            stall_num={car.stall_num}
            cardNumber={cardNumber}
            handleCardClick={setCardNumber}
          />
        )}

        {dumbcarStalls.map((car) =>
          car.card_num === cardNumber ? (
            <Grid item xs={6} sm={4} md={2} key={car.card_num}>
              <Button onClick={() => setCardNumber(Number(car.card_num))}>
                <Card sx={{ border: '2px solid green' }}>
                  <CardContent>
                    <Typography variant="subtitle1">
                      <b>card_num: {car.card_num}</b>
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>stall_num: {car.stall_num}</b>
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6} sm={4} md={2} key={car.card_num}>
              <Button onClick={() => setCardNumber(Number(car.card_num))}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">
                      card_num: {car.card_num}
                    </Typography>
                    <Typography variant="subtitle1">
                      stall_num: {car.stall_num}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
          )
        )}

        </Grid>
      </Paper>

      {newSwapCar && newExitCar && (
        <Grid container justifyContent="flex-end">
          <Typography variant="h6" align="center">
            Beta Feature
          </Typography>
          <Grid item>
            <Button 
              variant="contained" 
              onClick={() => newSwapCar(cardNumber)}
              sx={{ margin: 2 }}
            >
              Swap Car
            </Button>
          </Grid>
          <Grid item>
            <Button 
              variant="contained" 
              onClick={() => newExitCar(cardNumber)}
              sx={{ margin: 2 }}
            >
              Exit Car
            </Button>
          </Grid>
        </Grid>
      )}

    </>
  )
}

interface QueueProps {
  title: "Swap Queue" | "Exit Queue";
  queue: Car[];
  handleDeleteSwapEntry?: (card_num: number) => void
  newHandleDeleteSwapEntry?: (card_num: number) => void
  newHandleDeleteExitEntry?: (card_num: number) => void
  newHandleDeleteSwapEVEntry?: (card_num: number) => void
}

const QueueCards: React.FC<QueueProps> = ({ title, queue, handleDeleteSwapEntry, newHandleDeleteSwapEntry, newHandleDeleteExitEntry, newHandleDeleteSwapEVEntry  }) => {
  const [cardNumber, setCardNumber] = useState<number>(-1);

  const handleCardClick = (card_num: number) => {
    if (card_num !== 0) {
      setCardNumber(card_num);
    }
  }
  return (
    <Paper elevation={2} sx={{ marginY: 2 }}>
      <Typography variant="h5" align="center">
        {title}
      </Typography>

      <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
        {queue.map((car) => (
          <StdCard
            id={Number(car.id)}
            card_num={Number(car.card)}
            stall_num={Number(car.slot)}
            cardNumber={cardNumber}
            handleCardClick={handleCardClick}
          />
        ))}
      </Grid>

      {title === "Swap Queue" && handleDeleteSwapEntry && (
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {cardNumber !== -1 ? (
              <Button variant="contained" sx={{ margin: 2 }} onClick={() => handleDeleteSwapEntry(cardNumber)}>
                Remove from Swap Queue
              </Button>
            ) : (
              <Button variant="contained" sx={{ margin: 2 }} disabled>
                Remove from Swap Queue
              </Button>
              
            )}
          </Grid>
        </Grid>
      )}

      {title === "Swap Queue" && newHandleDeleteSwapEntry && newHandleDeleteSwapEVEntry && (
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography variant="h6" align="center">
            Beta Feature
          </Typography>
            {cardNumber !== -1 ? (
              <Button variant="contained" sx={{ margin: 2 }} onClick={() => newHandleDeleteSwapEntry(cardNumber)}>
                Remove EV Stall from Queue
              </Button>
            ) : (
              <Button variant="contained" sx={{ margin: 2 }} disabled>
                Remove EV Stall from Queue
              </Button>
            )}
            {cardNumber !== -1 ? (
              <Button variant="contained" sx={{ margin: 2 }} onClick={() => newHandleDeleteSwapEVEntry(cardNumber)}>
                Remove EV from Queue
              </Button>
            ) : (
              <Button variant="contained" sx={{ margin: 2 }} disabled>
                Remove EV from Queue
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      {title === "Exit Queue" && newHandleDeleteExitEntry && (
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="h6" align="center">
              Beta Feature
            </Typography>
            {cardNumber !== -1 ? (
              <Button variant="contained" sx={{ margin: 2 }} onClick={() => newHandleDeleteExitEntry(cardNumber)}>
                Remove from Exit Queue
              </Button>
            ) : (
              <Button variant="contained" sx={{ margin: 2 }} disabled>
                Remove from Exit Queue
              </Button>
              
            )}
          </Grid>
        </Grid>
      )}
      

    </Paper>
  );
};

interface EVStallCardProps {
  stall_num: number;
  card_num: number;
  stallNumber: string;
  setCardNumber: (card_num: number) => void;
  setStallNumber: (stall_num: string) => void;
}
const EVCard: React.FC<EVStallCardProps> = ({ stall_num, card_num, stallNumber, setCardNumber, setStallNumber }) => {
  const { garageLocation } = useGarageLocation()!;
  const endpoint = useEndpoint();

  const [okToPlug, setOkToPlug] = useState('loading....');
  const [isHolstered, setIsHolstered] = useState('loading...');
  const [evseState, setEvseState] = useState('loading...');
  const [evIsCharging, setEvIsCharging] = useState('loading...');

  const [lastE2eEvent, setLastE2EEvent] = useState('loading...');

  /// convert all the below useEffects into functions  and have one useEffect call them all in one go. Could also clean up backend to provide in one call...

  // is the vehicle ok to plug in
  useEffect(() => {
    const invalidCardNums = [0,1,996,997,998,999];
    if (invalidCardNums.includes(card_num)) {
      setOkToPlug('n/a');
      return
    }
    endpoint?.get('e2e/api/vehicle_ok_to_plug_in', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setOkToPlug(`${response.data}`);
    }).catch(error => {
      console.error(`There was an error finding out if vehicle ${card_num} at ${garageLocation} is ok to plug in!: ${error}`);
      setOkToPlug('n/a');
    });
  }, [garageLocation, endpoint, card_num]);

  // get holster status
  useEffect(() => {
    endpoint?.get('holster_checker/api/holster_status_for_stall', {
      params: {
        location: garageLocation,
        stall_num: stall_num
      },
      withCredentials: true
    }).then(response => {
        setIsHolstered(`${response.data.is_holstered}`);
    }).catch(error => {
      console.error(`There was an error finding out holster status at stall ${stall_num} at ${garageLocation}!: ${error}`);
    });
  }, [garageLocation, endpoint, stall_num]);

  // get ev_is_charging status
  useEffect(() => {
    endpoint?.get('sotefin/api/ev_is_charging', {
      params: {
        location: garageLocation,
        stall_num: stall_num
      },
      withCredentials: true
    }).then(response => {
        setEvIsCharging(`${response.data}`);
    }).catch(error => {
      console.error(`There was an error finding out sotefin ev charging status at stall ${stall_num} at ${garageLocation}!: ${error}`);
    });
  }, [garageLocation, endpoint, stall_num]);

  // get evse state
  useEffect(() => {
    endpoint?.get('ocpp/api/charging_state', {
      params: {
        location: garageLocation,
        stall_num: stall_num
      },
      withCredentials: true
    }).then(response => {
        setEvseState(`${response.data.status}`);
    }).catch(error => {
      console.error(`There was an error finding out evse state for ${stall_num} at ${garageLocation}: ${error}`);
      setEvseState('n/a');
    });
  }, [garageLocation, endpoint, stall_num]);

  // get last e2e event
  useEffect(() => {
    const invalidCardNums = [0,1,996,997,998,999];
    if (invalidCardNums.includes(card_num)) {
      setLastE2EEvent('n/a');
      return;
    }
    endpoint?.get('e2e/api/get_last_e2e_event', {
      params: {
        location: garageLocation,
        stall_num: stall_num
      }
    }).then(response => {
      setLastE2EEvent(response.data);
    }).catch(error => {
      console.error(`There was an error finding out last e2e event at stall ${stall_num} at ${garageLocation}!: ${error}`);
    })
  }, [garageLocation, endpoint, stall_num, card_num]);

  return (
    <Grid item sx={{ minWidth: 120 }} key={stall_num}>
      <Button onClick={ () => {
        setCardNumber(card_num);
        setStallNumber(stall_num.toString())
      }}>
        {stall_num === parseInt(stallNumber) ?
        <Card sx={{ border: '2px solid green' }}>
          <CardContent>
            <Typography variant="subtitle1">
              <b>stall_num: {stall_num}</b>
            </Typography>
            <Typography variant="subtitle1">
              <b>card_num: {card_num}</b>
            </Typography>
            <Divider variant="middle" />
            <Typography variant="subtitle2">
              <b>is_holstered: {isHolstered}</b>
            </Typography>
            <Typography variant="subtitle2">
              <b>ok_to_plug: {okToPlug}</b>
            </Typography>
            <Typography variant="subtitle2">
              <b>evse_state: {evseState}</b>
            </Typography>
            <Typography variant="subtitle2">
              <b>last_e2e_event: {lastE2eEvent}</b>
            </Typography>
            <Typography variant="subtitle2">
              <b>sotefin_ev_is_charging: {evIsCharging}</b>
            </Typography>
          </CardContent>
        </Card>
        :
        <Card>
          <CardContent>
            <Typography variant="subtitle1">
              stall_num: {stall_num}
            </Typography>
            <Typography variant="subtitle1">
              card_num: {card_num}
            </Typography>
            <Divider variant="middle" />
            <Typography variant="subtitle2">
              is_holstered: {isHolstered}
            </Typography>
            <Typography variant="subtitle2">
              ok_to_plug: {okToPlug}
            </Typography>
            <Typography variant="subtitle2">
              evse_state: {evseState}
            </Typography>
            <Typography variant="subtitle2">
              last_e2e_event: {lastE2eEvent}
            </Typography>
            <Typography variant="subtitle2">
              sotefin_ev_is_charging: {evIsCharging}
            </Typography>
          </CardContent>
        </Card>
        }
        
      </Button>
    </Grid>
  )
}

interface StdCardProps {
  id?: number;
  stall_num: number;
  card_num: number;
  cardNumber: number;
  handleCardClick: (card_num: number) => void;
}

const StdCard: React.FC<StdCardProps> = ({ id, stall_num, card_num, cardNumber, handleCardClick }) => {

  return (
    cardNumber === card_num ? (
      <Grid item xs={6} sm={4} md={2} key={id}>
        <Button onClick={() => handleCardClick(card_num)}>
          <Card sx={{ border: '2px solid green' }}>
            <CardContent>
              { id && (
                <>
                  <Typography variant="h6" align="center">
                    <b>{id}</b>
                  </Typography>
                  <Divider variant="middle" />
                </>
              )}
              <Typography variant="subtitle1">
                <b>card_num: {card_num}</b>
              </Typography>
              <Typography variant="subtitle1">
                <b>stall_num: {stall_num}</b>
              </Typography>
            </CardContent>
          </Card>
        </Button>
      </Grid>
    ) : (
      <Grid item xs={6} sm={4} md={2} key={id}>
        <Button onClick={() => handleCardClick(card_num)}>
          <Card>
            <CardContent>
              { id && (
                <>
                  <Typography variant="h6" align="center">
                    {id}
                  </Typography>
                  <Divider variant="middle" />
                </>
              )}
              <Typography variant="subtitle1">
                card_num: {card_num}
              </Typography>
              <Typography variant="subtitle1">
                stall_num: {stall_num}
              </Typography>
            </CardContent>
          </Card>
        </Button>
      </Grid>
      )
  )
}

export default Sotefin;