import React from 'react';
import { Box, Chip, TextField, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';


export enum ChipType {
    GARAGE = 'garage',
    EV = 'ev',
    CARD = 'card',
}
export interface ChipData<MetaData> {
    id: string;
    title: string;
    selected: boolean;
    type: ChipType;
    data: MetaData;
}

export interface ChipSelectorProps {
  allChips: ChipData<any>[];
  selectedChips: ChipData<any>[];
  searchTerm: string;
  onChipsChange: (chips: ChipData<any>[]) => void;
  onSearchTermChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const NotificationFilterBar: React.FC<ChipSelectorProps> = ({ allChips, selectedChips, searchTerm, onChipsChange, onSearchTermChanged }) => {
  const handleChipClick = (chip: ChipData<any>) => {
    if (selectedChips.includes(chip)) {
      onChipsChange(selectedChips.filter((c) => c.id !== chip.id));
    } else {
      onChipsChange([...selectedChips, chip]);
    }
  };

  const filteredUnselectedChips = allChips.filter(
    (chip) => !selectedChips.some((c) => c.id === chip.id) && chip.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
  );

  return (
    <Box>
        <Box mb={2}>
            <Typography variant="h6">Applied Filters</Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
                {selectedChips.length > 0 ? (
                selectedChips.map((chip: ChipData<any>) => (
                    <Chip
                        key={chip.id}
                        label={chip.title}
                        onClick={() => handleChipClick(chip)}
                        color="primary"
                        deleteIcon={<CancelIcon />}
                        onDelete={() => handleChipClick(chip)}
                    />
                ))
                ) : (
                    <Chip label="No Filters" disabled />
                )}
            </Box>
        </Box>

        <Box>
            <Typography variant="h6">Unselected Filters</Typography>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={onSearchTermChanged}
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
            />
            <Box display="flex" flexWrap="wrap" gap={1}>
            {filteredUnselectedChips.map((chip) => (
                <Chip
                key={chip.id}
                label={chip.title}
                onClick={() => handleChipClick(chip)}
                variant="outlined"
                />
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationFilterBar;