import { useState, useEffect, ReactElement } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Container, Typography, Paper } from '@mui/material';
import { useAccessToken } from '../hooks/access-token.hook';

interface ValidateAccessProps {
  permission: string, 
  children: ReactElement
}
export function ValidateAccess({ permission, children }: ValidateAccessProps): ReactElement | null {
  const accessToken = useAccessToken();
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  useEffect(() => {
    if (accessToken) {
      const token = jwtDecode(accessToken) as unknown as { permissions: string[] };
      token && setHasAccess(token.permissions.includes(permission));
    }
  }, [accessToken, permission]);


  if (hasAccess == null)
    return null;
  else if (hasAccess === false)
    return <AccessDeniedPage />;

  return children;
};

export function AccessDeniedPage() {
  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" align="center" color="error">
          Access Denied
        </Typography>
      </Paper>
    </Container>
  );
}