import React, { useState, useEffect } from 'react';

import {
  Card,
  Box,
  Container,
  Typography,
  TextField,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Paper,
} from '@mui/material';

import NotificationFilterBar, { ChipData, ChipType } from '../components/NotificationFilterBar';
import { useEndpoint } from '../hooks/endpoint.hook';
import { Endpoint } from '../util/endpoint.util';

interface User {
  id: number;
  createdAt: Date;
  auth0_sub: string | null;
  card_num: number;
  location: string;
  vehicle_picture: string | null;
  smartcar: boolean;
}
export interface Notification {
  id: string;
  type: string;
  message: string;
  timestamp: Date;
  auth0UserSub: string | string[];
  dismissed?: boolean;
  read?: boolean;
  createdBy: string;
  recipients?: string[];
  filters?: {
    ev: boolean;
    garages: string[];
    card_ids: string[];
  }
}

const NotificationManagement = () => {
  const [message, setMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [users, setUsers] = useState<User[] | null>(null)
  const [history, setHistory] = useState<Notification[]>([]);
  const [garages] = useState<ChipData<any>[]>([
    {
      id: 'menlo_a',
      title: 'Menlo A',
      selected: false,
      type: ChipType.GARAGE,
      data: 'menlo_a',
    },
    {
      id: 'menlo_b',
      title: 'Menlo B',
      selected: false,
      type: ChipType.GARAGE,
      data: 'menlo_b',
    },
    {
      id: 'spire',
      title: 'Spire',
      selected: false,
      type: ChipType.GARAGE,
      data: 'spire',
    },
  ]);
  const carTypeFilters: ChipData<any>[] = [
    {
      id: 'ev',
      title: 'EV',
      selected: false,
      type: ChipType.EV,
      data: 'ev_filter',
    },
    {
      id: 'non-ev',
      title: 'Non EV Cars',
      selected: false,
      type: ChipType.EV,
      data: 'ev_filter',
    },
  ]
  const endpoint = useEndpoint();
  const [selectedChips, setSelectedChips] = useState<ChipData<any>[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const clearMessageBox = () => {
    setMessage('');
  }

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const getUsers = (endpoint: Endpoint) => {
    return endpoint?.get('admin/api/users', {
      params: {}
    })
      .then((response: any) => response.data)
      .catch(console.error)
  };

  const getHistory = (endpoint: Endpoint) => {
    return endpoint?.get('admin/api/notification-history', {
      params: {}
    })
      .then((response: any) => response.data)
      .catch(console.error)
  };

  function userToChip(user: User): ChipData<any> {
    return {
      id: user.card_num.toString(),
      title: `card: ${user.card_num.toString()}`,
      selected: false,
      type: ChipType.CARD,
      data: user,
    };
  }

  function sortUserChip(a: ChipData<any>, b: ChipData<any>): number {
    return a.data.card_num - b.data.card_num;
  }

  useEffect(() => {
    endpoint && getUsers(endpoint)
      .then((users: User[]) => setUsers(users as unknown as any))
      .catch(console.error);
    endpoint && getHistory(endpoint)
      .then((history: any[]) => setHistory((history as any).notifications))
      .catch(console.error);
  }, [endpoint, selectedChips]); 

  const handleChipsChanged = (chips: ChipData<any>[]) => {
    setSelectedChips(chips);
  };

  const handleSendNotification = async () => {
    let ev_filter = undefined
    let ev_filter_chips = selectedChips.filter(chip => chip.type === ChipType.EV)

    if (ev_filter_chips.length === 1) {
      ev_filter = ev_filter_chips[0].id === 'ev'
    }

    await endpoint?.post('admin/api/send-notifications', {
      data: {
        message: message,
        filters: {
          garages: selectedChips.filter(chip => chip.type === ChipType.GARAGE).map(chip => chip.data),
          card_ids: selectedChips.filter(chip => chip.type === ChipType.CARD).map(chip => chip.id),
          ev: ev_filter
        }
      }
    })

    handleCloseConfirmDialog();
    setSearchTerm('')
    clearMessageBox()
    setSelectedChips([])
    setTimeout(() => {
      endpoint && getHistory(endpoint)
    }, 800)
  };

  const handleMessageChange = (event: any) => {
    const value = event.target.value;
    if (value.length <= 256) {
      setMessage(value);
    }
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Notification Management
        </Typography>
        <NotificationFilterBar
          allChips={[...garages, ...carTypeFilters, ...(users || []).map(userToChip).sort(sortUserChip)]}
          selectedChips={selectedChips}
          searchTerm={searchTerm}
          onSearchTermChanged={handleSearchChange}
          onChipsChange={handleChipsChanged}
        />

        <Box pt={6}>
          <Card>
            <CardContent>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Create Notification
                </Typography>
                <TextField
                  label="Message"
                  value={message}
                  onChange={handleMessageChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 256 }}
                  helperText={`${message.length}/256 characters`}
                />
                <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                  <Button variant="contained" color="primary" disabled={!message} onClick={clearMessageBox}>
                    Clear 
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleOpenConfirmDialog}>
                    Send
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Dialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title">Confirm Notification</DialogTitle>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                {message}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSendNotification}  color="primary" autoFocus>
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Notification History
        </Typography>
        <List>
          {!history.length && <ListItem>No notifications</ListItem>}
          {history.map((notification) => (
            <ListItem key={notification.id}>
              <Paper elevation={3} sx={{ p: 2, width: '100%' }}>
                <Typography variant="subtitle1" gutterBottom>
                  {new Date(notification.timestamp).toLocaleString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {notification.message}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Recipients: {notification.recipients?.join(', ')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Filters:
                  <ul>
                    <li>EV: {notification.filters?.ev === undefined ? 'unset' : notification.filters.ev.toString()}</li>
                    <li>Garage: {notification.filters?.garages === undefined ? 'unset' : notification.filters?.garages.join(', ')}</li>
                    <li>Card IDs: {notification.filters?.card_ids.join(', ')}</li>
                  </ul>
                </Typography>
              </Paper>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  )
};

export default NotificationManagement;